import {
  Button,
  Color,
  EmpresaDarkIcon,
  EnvioDarkIcon,
  EnvioLightIcon,
  ProfileDarkIcon,
  ProfileLightIcon,
  TrolleyDarkIcon,
  TrolleyLightIcon,
  Typography,
} from '@objectedge/pecai-storefront-ds';
import clsx from 'clsx';
import React, { useState } from 'react';
import Loader from '~/components/common/Loader';
import { CheckCircle } from '~/components/icons/CheckCircle';
import {
  BackofficeBuyer,
  RequestAccountContactType,
  RequestAccountShopSize,
  useRequestAccountMutation,
} from '~/operations';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import { Page, useNavigation } from '~/utils/navigation';
import { useAlerts } from '~/utils/useAlerts';
import styles from './ModalCustomerRegistrationForm.module.scss';
import CompanyAddressForm from './subcomponents/CompanyAddressForm';
import CompanyDataForm from './subcomponents/CompanyDataForm';
import PersonalDataForm from './subcomponents/PersonalDataForm';
import ShopDetailsForm from './subcomponents/ShopDetailsForm';

export interface optionsProps {
  label: string;
  value: string | number;
}

export interface checkboxProps {}

enum formSteps {
  COMPANY_DETAIL = 1,
  SHOP_DETAIL = 2,
  ADDRESS_DETAIL = 3,
  ACCOUNT_DETAIL = 4,
}

export interface formStateValuesProps {
  addressCEP: string;
  addressCity: string;
  addressState: optionsProps | {} | string;
  addressNeighborhood: string;
  cpf: string;
  cnpj: string;
  email: string;
  password: string;
  confirmPassword: string;
  fantasia: string;
  razaosocial: string;
  addressStreet: string;
  addressNumber: string;
  addressComplement: string;
  responsavel: string;
  celular: string;
  additionalEmail: string;
  origin:string;
  optIn: boolean;
  policy: boolean;
  buyersGroups: optionsProps[] | [] | string;
  averageClients: optionsProps | {} | string;
  employees: optionsProps | {} | string;
  elevators: optionsProps | {} | string;
  itemsAvailable: optionsProps[];
  itemsAvailableOther?: string;
  lineBusiness: optionsProps | {} | string;
  agreement?: {
    message: string;
  };
}

const mapToInput = (values) => {
  const map = {
    password: values.password,
    commercialName: values.razaosocial,
    tradingName: values.fantasia,
    documentNumber: values.cnpj.replace(/\D/g, ''),
    numOfDailyCustomers: values.averageClients.value,
    numOfEmployees: values.employees.value,
    numOfElevators: values.elevators.value,
    itemsInStock: values.itemsAvailable.map((o) => o.label),
    shopSize:
      values.employees > 15
        ? RequestAccountShopSize.G
        : values.employees > 7
        ? RequestAccountShopSize.M
        : RequestAccountShopSize.P,
    responsible: {
      name: values.responsavel,
      documentNumber: values.cpf.replace(/\D/g, ''),
      contacts: [
        { type: RequestAccountContactType.Email, value: values.email },
        { type: RequestAccountContactType.Cellphone, value: values.celular.replace(/\D/g, '') },
      ],
    },
    address: {
      zipCode: values.addressCEP,
      street: values.addressStreet,
      number: values.addressNumber,
      complement: values.addressComplement,
      neighborhood: values.addressNeighborhood,
      state: values.addressState?.label || '',
      city: values.addressCity,
    },
    buyersGroups: values.buyersGroups?.map((e) => e.label),
    branchOfActivity: values.lineBusiness?.label,
    origin: values.origin.value
  };

  if (values.additionalEmail) {
    map.responsible.contacts.push({ type: RequestAccountContactType.FinancialEmail, value: values.additionalEmail });
  }

  if (values.itemsAvailableOther) {
    map.itemsInStock.push(values.itemsAvailableOther);
  }

  return map;
};

interface ModalCustomerRegistrationFormProps {
  handleCloseModal: () => void;
  handleGoToLoginModal: () => void;
  setRegisterForm: (buyer: BackofficeBuyer) => void;
}

const ModalCustomerRegistrationForm: React.FC<ModalCustomerRegistrationFormProps> = ({
  handleCloseModal,
  handleGoToLoginModal,
  setRegisterForm,
}) => {
  const { goToPage } = useNavigation();
  const [registerFormSteps, setRegisterFormSteps] = useState<number>(1);
  const [registerSuccess, setRegisterSuccess] = useState<BackofficeBuyer>();
  const [formStateValues, setFormStateValues] = useState<formStateValuesProps>({
    addressCEP: '',
    addressCity: '',
    addressState: {},
    addressNeighborhood: '',
    cpf: '',
    cnpj: '',
    email: '',
    password: '',
    confirmPassword: '',
    fantasia: '',
    razaosocial: '',
    addressStreet: '',
    addressNumber: '',
    addressComplement: '',
    responsavel: '',
    celular: '',
    additionalEmail: '',
    optIn: true,
    policy: true,
    buyersGroups: [],
    averageClients: {},
    employees: {},
    elevators: {},
    itemsAvailable: [],
    itemsAvailableOther: '',
    lineBusiness: {},
    origin: ''
  });
  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });
  const isTabletView = useBreakpoint({ min: Breakpoint.md, max: Breakpoint.lg });

  const { actions } = useAlerts();
  const [loading, setLoading] = useState(false);
  const [requestAccountMutation, { loading: pecaiPartnerRegistrationLoading }] = useRequestAccountMutation();

  const handleNextFormStep = () => {
    setRegisterFormSteps(registerFormSteps + 1);
  };

  const handlePreviousFormStep = () => {
    setRegisterFormSteps(registerFormSteps - 1);
  };

  const handleSuccessRegistration = (register: BackofficeBuyer) => {
    setRegisterFormSteps(0);
    setRegisterSuccess(register);
    setRegisterForm(register);
  };

  const handleOnSubmit = async (values) => {
    try {
      const { data } = await requestAccountMutation({
        variables: { input: mapToInput(values) },
      });

      const { success, edge, error } = data?.requestAccount || {};
      if (success) {
        handleSuccessRegistration(edge as BackofficeBuyer);
      } else {
        actions.removeAllAlerts();
        if (error?.errors?.some((error) => error?.field === 'cnpj')) {
          actions.addDangerAlert(
            'Empresa já cadastrada (ou com avaliação de cadastro em andamento). Entre em contato com o suporte Peça aí em caso de dúvidas de acesso a plataforma.'
          );
          return;
        }
        if (error?.detail === 'Erro ao criar usuário.') {
          actions.addDangerAlert(
            'Já existe um usuário com este email/CPF. Entre em contato com o suporte Peça aí em caso de dúvidas de acesso a plataforma.'
          );
          return;
        } else {
          actions.addDangerAlert(
            'Um erro ocorreu ao realizar o seu cadastro. Caso o problema persista, por favor entre em contato com o suporte Peça aí.'
          );
          return;
        }
      }
    } catch (e) {
      actions.addDangerAlert(
        'Um erro ocorreu ao realizar o seu cadastro. Caso o problema persista, favor entre em contato com o suporte Peça aí.'
      );
      return;
    }
  };

  return (
    <>
      {!registerSuccess && !isMobileView && !isMiniMobileView ? (
        <div className={styles.stepsContainer}>
          <div className={clsx(styles.stepOneContainer, styles.dark)}>
            <div className={styles.contentContainer}>
              <EmpresaDarkIcon size={isTabletView ? 32 : 64} />
              <div className={styles.textsContainer}>
                <Typography variant="body-regular" weight="bold" color="primary-900">
                  Passo 1
                </Typography>
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes da empresa
                </Typography>
              </div>
            </div>
          </div>
          <div className={clsx(styles.stepTwoContainer, registerFormSteps >= formSteps.SHOP_DETAIL && styles.dark)}>
            <div className={styles.contentContainer}>
              {registerFormSteps >= formSteps.SHOP_DETAIL ? (
                <TrolleyDarkIcon size={isTabletView ? 32 : 64} />
              ) : (
                <TrolleyLightIcon size={isTabletView ? 32 : 64} />
              )}
              <div className={styles.textsContainer}>
                <Typography variant="body-regular" weight="bold" color="primary-900">
                  Passo 2
                </Typography>
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes da oficina
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={clsx(styles.stepThreeContainer, registerFormSteps >= formSteps.ADDRESS_DETAIL && styles.dark)}
          >
            <div className={styles.contentContainer}>
              {registerFormSteps >= formSteps.ADDRESS_DETAIL ? (
                <EnvioDarkIcon size={isTabletView ? 32 : 64} />
              ) : (
                <EnvioLightIcon size={isTabletView ? 32 : 64} />
              )}
              <div className={styles.textsContainer}>
                <Typography variant="body-regular" weight="bold" color="primary-900">
                  Passo 3
                </Typography>
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes de envio
                </Typography>
              </div>
            </div>
          </div>

          <div className={clsx(styles.stepTwoContainer, registerFormSteps >= formSteps.ACCOUNT_DETAIL && styles.dark)}>
            <div className={styles.contentContainer}>
              {registerFormSteps >= formSteps.ACCOUNT_DETAIL ? (
                <ProfileDarkIcon size={isTabletView ? 32 : 64} />
              ) : (
                <ProfileLightIcon size={isTabletView ? 32 : 64} />
              )}
              <div className={styles.textsContainer}>
                <Typography variant="body-regular" weight="bold" color="primary-900">
                  Passo 4
                </Typography>
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes da conta
                </Typography>
              </div>
            </div>
          </div>
        </div>
      ) : !registerSuccess && isMobileView && !isMiniMobileView ? (
        <div className={styles.mobileStepsContainer}>
          <div className={clsx(styles.stepOneContainer, styles.dark)}>
            <div className={styles.contentContainer}>
              <div className={styles.upperContainer}>
                <EmpresaDarkIcon size={32} />
                <Typography variant="body-regular" weight="bold" color="primary-900">
                  Passo 1
                </Typography>
              </div>
              <div
                className={clsx(
                  styles.bottomContainer,
                  registerFormSteps !== formSteps.COMPANY_DETAIL && styles.noText
                )}
              >
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes da empresa
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={clsx(
              styles.stepTwoContainer,
              registerFormSteps === formSteps.ACCOUNT_DETAIL && styles.dark,
              registerFormSteps === formSteps.ADDRESS_DETAIL && styles.dark
            )}
          >
            <div className={styles.contentContainer}>
              <div className={styles.upperContainer}>
                {registerFormSteps === formSteps.ACCOUNT_DETAIL || registerFormSteps === formSteps.ADDRESS_DETAIL ? (
                  <ProfileDarkIcon size={32} />
                ) : (
                  <ProfileLightIcon size={32} />
                )}
                <Typography
                  variant="body-regular"
                  weight="bold"
                  color={
                    registerFormSteps === formSteps.ACCOUNT_DETAIL || registerFormSteps === formSteps.ADDRESS_DETAIL
                      ? 'primary-900'
                      : 'primary-200'
                  }
                >
                  Passo 2
                </Typography>
              </div>
              <div
                className={clsx(
                  styles.bottomContainer,
                  registerFormSteps !== formSteps.ACCOUNT_DETAIL && styles.noText
                )}
              >
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes da conta
                </Typography>
              </div>
            </div>
          </div>

          <div
            className={clsx(styles.stepThreeContainer, registerFormSteps === formSteps.ADDRESS_DETAIL && styles.dark)}
          >
            <div className={styles.contentContainer}>
              <div className={styles.upperContainer}>
                {registerFormSteps === formSteps.ADDRESS_DETAIL ? (
                  <EnvioDarkIcon size={32} />
                ) : (
                  <EnvioLightIcon size={32} />
                )}
                <Typography
                  variant="body-regular"
                  weight="bold"
                  color={registerFormSteps === formSteps.ADDRESS_DETAIL ? 'primary-900' : 'primary-200'}
                >
                  Passo 3
                </Typography>
              </div>
              <div
                className={clsx(
                  styles.bottomContainer,
                  registerFormSteps !== formSteps.ADDRESS_DETAIL && styles.noText
                )}
              >
                <Typography variant="body-small" weight="light" color="primary-900">
                  Detalhes de envio
                </Typography>
              </div>
            </div>
          </div>
        </div>
      ) : (
        !registerSuccess && (
          <div className={styles.mobileStepsContainer}>
            <div className={clsx(styles.stepOneContainer, styles.dark)}>
              <div className={styles.contentContainer}>
                <div className={styles.upperContainer}>
                  <EmpresaDarkIcon size={32} />
                  <Typography
                    variant={isMiniMobileView ? 'body-xsmall' : 'body-regular'}
                    weight="bold"
                    color="primary-900"
                  >
                    {registerFormSteps !== formSteps.COMPANY_DETAIL ? '' : 'Passo 1'}
                  </Typography>
                </div>
                <div className={styles.bottomContainer}>
                  <Typography variant="body-small" weight="light" color="primary-900">
                    {registerFormSteps === formSteps.COMPANY_DETAIL && 'Detalhes da empresa'}
                    {registerFormSteps === formSteps.SHOP_DETAIL && 'Detalhes da oficina'}
                    {registerFormSteps === formSteps.ACCOUNT_DETAIL && 'Detalhes da conta'}
                    {registerFormSteps === formSteps.ADDRESS_DETAIL && 'Detalhes de envio'}
                  </Typography>
                </div>
              </div>
            </div>

            <div
              className={clsx(
                styles.stepTwoContainer,
                registerFormSteps === formSteps.ACCOUNT_DETAIL && styles.dark,
                registerFormSteps === formSteps.ADDRESS_DETAIL && styles.dark
              )}
            >
              <div className={styles.contentContainer}>
                <div
                  className={clsx(
                    styles.upperContainer,
                    registerFormSteps === formSteps.COMPANY_DETAIL && styles.stepOne
                  )}
                >
                  {registerFormSteps >= formSteps.ACCOUNT_DETAIL ? (
                    <ProfileDarkIcon size={32} />
                  ) : (
                    <ProfileLightIcon size={32} />
                  )}
                  <Typography
                    variant={isMiniMobileView ? 'body-xsmall' : 'body-regular'}
                    weight="bold"
                    color={registerFormSteps >= formSteps.ACCOUNT_DETAIL ? 'primary-900' : 'primary-200'}
                  >
                    {registerFormSteps !== formSteps.ACCOUNT_DETAIL ? '' : 'Passo 2'}
                  </Typography>
                </div>
                <div className={clsx(styles.bottomContainer, styles.noText)}>
                  <Typography variant="body-small" weight="light" color="primary-900">
                    Detalhes da conta
                  </Typography>
                </div>
              </div>
            </div>

            <div
              className={clsx(styles.stepThreeContainer, registerFormSteps === formSteps.ADDRESS_DETAIL && styles.dark)}
            >
              <div className={styles.contentContainer}>
                <div
                  className={clsx(
                    styles.upperContainer,
                    registerFormSteps === formSteps.COMPANY_DETAIL && styles.stepOne,
                    registerFormSteps === formSteps.ACCOUNT_DETAIL && styles.stepTwo
                  )}
                >
                  {registerFormSteps === formSteps.ADDRESS_DETAIL ? (
                    <EnvioDarkIcon size={32} />
                  ) : (
                    <EnvioLightIcon size={32} />
                  )}
                  <Typography
                    variant={isMiniMobileView ? 'body-xsmall' : 'body-regular'}
                    weight="bold"
                    color={registerFormSteps === formSteps.ADDRESS_DETAIL ? 'primary-900' : 'primary-200'}
                  >
                    {registerFormSteps !== formSteps.ADDRESS_DETAIL ? '' : 'Passo 3'}
                  </Typography>
                </div>
                <div className={clsx(styles.bottomContainer, styles.noText)}>
                  <Typography variant="body-small" weight="light" color="primary-900">
                    Detalhes de envio
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {registerFormSteps === formSteps.COMPANY_DETAIL && (
        <CompanyDataForm
          handleNextFormStep={handleNextFormStep}
          handleCloseModal={handleCloseModal}
          formStateValues={formStateValues}
          setFormStateValues={setFormStateValues}
        />
      )}

      {registerFormSteps === formSteps.SHOP_DETAIL && (
        <ShopDetailsForm
          handleNextFormStep={handleNextFormStep}
          handlePreviousFormStep={handlePreviousFormStep}
          formStateValues={formStateValues}
          setFormStateValues={setFormStateValues}
        />
      )}

      {registerFormSteps === formSteps.ADDRESS_DETAIL && (
        <CompanyAddressForm
          loading={pecaiPartnerRegistrationLoading}
          setLoading={setLoading}
          formStateValues={formStateValues}
          setFormStateValues={setFormStateValues}
          handlePreviousFormStep={handlePreviousFormStep}
          handleNextFormStep={handleNextFormStep}
        />
      )}

      {registerFormSteps === formSteps.ACCOUNT_DETAIL && (
        <PersonalDataForm
          loading={pecaiPartnerRegistrationLoading}
          handlePreviousFormStep={handlePreviousFormStep}
          formStateValues={formStateValues}
          setFormStateValues={setFormStateValues}
          handleSubmitAllFormData={handleOnSubmit}
        />
      )}

      {registerSuccess && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div className={styles.checkCircle}>
            <CheckCircle />
          </div>
          {registerSuccess.active ? (
            <Button onClick={handleGoToLoginModal} block color={Color.PRIMARY}>
              Faça seu login
            </Button>
          ) : (
            <>
              <Typography variant="body-large" className="mb-5">
                Seu cadastro será analisado e em breve receberá a liberação.
              </Typography>
              <Button
                onClick={() => {
                  handleCloseModal();
                  goToPage(Page.home);
                }}
                block
                color={Color.PRIMARY}
              >
                Voltar para a Home
              </Button>
            </>
          )}
        </div>
      )}

      {loading && (
        <div>
          <Loader full />
        </div>
      )}
    </>
  );
};

export default ModalCustomerRegistrationForm;
